import React, { useRef, useState, useEffect, Suspense } from "react"
import { useParams, useNavigate } from "react-router-dom"

import { Canvas, useLoader } from "@react-three/fiber"
import { OrbitControls, Html, useContextBridge } from "@react-three/drei"
import { BackSide, VideoTexture, TextureLoader } from "three"

import { configureStore, createSlice } from "@reduxjs/toolkit"
import { Provider, useSelector, useDispatch, ReactReduxContext } from "react-redux"

import PanoramaMenu from "./PanoramaMenu"

import { AnimatePresence, motion } from "framer-motion"
import { InView } from 'react-intersection-observer';

import { Button } from "antd"
import "antd/dist/antd.min.css"

import { AudioOffIcon, AudioOnIcon, GalleryIcon, PanoramaIcon } from "./icons"

import debounce from 'lodash.debounce'
import seed from "seed-random"

import tour from "./tour.json"

import "./Viewer.css"
import ContributeButton from "./ContributeButton"

import CONSTANTS from "./constants.json"

/* constants */

const { resolutions, memoryTypes } = CONSTANTS

/* RTK Slices */

const tourSlice = createSlice({
  name: "tour",
  initialState: { ...tour },
  reducers: {},
})

const viewerSlice = createSlice({
  name: "viewer",
  initialState: {
    sceneHistory: [],
    isMuted: true
  },
  reducers: {
    setScene: (state, action) => {
      const { sceneId } = action.payload
      state.sceneHistory = [sceneId, ...state.sceneHistory.filter(id => id !== sceneId)]
    },
    toggleAudio: (state) => {
      state.isMuted = !state.isMuted
    },
  }
})

/* RTK Store */

export const store = configureStore({
  reducer: {
    tour: tourSlice.reducer,
    viewer: viewerSlice.reducer
  },
})

/* Utilities */

const vw = value => (window.innerWidth / 100) * value
const vh = value => (window.innerHeight / 100) * value
const rem = value => value * 16

var smartypants = require("smartypants")

const format = (text) => (
  <span dangerouslySetInnerHTML={{ __html: smartypants.smartypants(text) }} />
)

const degToRad = (deg) => (deg * Math.PI) / 180

/* Components */

const Viewer = () => {
  const { sceneId } = useParams()
  return (
    <Provider store={store}>
      <ViewerUI sceneId={sceneId} />
    </Provider>
  )
}

const ViewerUI = ({ sceneId }) => {
  const dispatch = useDispatch()

  const sceneLoaded = useSelector(state => state.viewer.sceneHistory.length > 0)

  const pageTitle = useSelector(state => {
    //console.log(sceneId)
    if (sceneId) {
      const sceneCategory = state.tour.sceneMenu.find(cat => cat.children.includes(sceneId)).name
      const sceneDescription = state.tour.scenes[sceneId].description
      return [sceneCategory, sceneDescription]
    } else {
      return []
    }
  })

  useEffect(() => {
    document.body.id = "viewer"
  }, [])

  useEffect(() => {
    const baseTitle = "The Farm in Wells"
    document.title = [baseTitle, ...pageTitle].join(' — ')
  }, [pageTitle])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
    dispatch(viewerSlice.actions.setScene({ sceneId: sceneId }))
  }, [sceneId, dispatch])

  return (
    <>
      {
        sceneLoaded && (<>
          <ScenePanorama />
          <PanoramaMenu currentPath={sceneId} />
          <SoundToggleButton />
          <PhotoGallery />
        </>)
      }
    </>
  )
}

const SoundToggleButton = () => {
  const isMuted = useSelector((state) => state.viewer.isMuted)

  const dispatch = useDispatch()
  const toggleAudio = () => {
    dispatch(viewerSlice.actions.toggleAudio())
  }

  return (
    <div id="sound-toggle-button">
      <Button size="large" onClick={toggleAudio}>
        {isMuted && <span id="sound-toggle-message" className="button-text ui-text collapse"><span className="primary">Play Ambient Sound</span></span>}
        <span className="button-icon">{isMuted ? <AudioOffIcon /> : <AudioOnIcon />}</span>
      </Button>
    </div>
  )
}

const ScenePanorama = () => {

  const videoRef = useRef()

  const navigate = useNavigate()
  const onNavigate = (to) => {
    navigate(to)
  }

  const ContextBridge = useContextBridge(ReactReduxContext)

  return (
    <div id="scene">
      <SourceVideo videoRef={videoRef} />
      <Canvas className="canvas">
        <ContextBridge>
          <Photosphere
            videoRef={videoRef}
            navigate={onNavigate}
          />
        </ContextBridge>
      </Canvas>
    </div>
  )
}

const SourceVideo = ({ videoRef }) => {
  const sceneId = useSelector((state) => state.viewer.sceneHistory[0])
  const panoramaId = useSelector((state) => state.tour.scenes[state.viewer.sceneHistory[0]].panoramaId)
  const isMuted = useSelector((state) => state.viewer.isMuted)


  useEffect(() => {
    const playVideo = () => {
      const isPlaying = !!(videoRef.current.currentTime > 0 && !videoRef.current.paused && !videoRef.current.ended && videoRef.current.readyState > 2)
      if (!isPlaying) {
        videoRef.current.play()
      }
    }
    videoRef.current.addEventListener("canplay", playVideo)
  }, [sceneId, videoRef])

  return (
    <video
      poster={`${process.env.PUBLIC_URL}/assets/panoramas/${panoramaId}.jpg`}
      ref={videoRef}
      key={sceneId}
      loop
      muted={isMuted}
      playsInline
      autoPlay
      crossOrigin="anonymous"
      style={{
        position: "absolute",
        left: "-10000px",
      }}
    >
      <source
        src={`${process.env.PUBLIC_URL}/assets/panoramas/${panoramaId}.mp4`}
      />
    </video>
  )
}

const Photosphere = ({ videoRef, navigate }) => {
  const rotateSpeed = window.devicePixelRatio > 1 ? -0.5 : -0.9 // slower rotation on high DPI screens
  const enableZoom = false
  const minPolarAngle = Math.PI * 0.3

  return (
    <>
      <Sphere
        videoRef={videoRef}
        navigate={navigate}
      />
      <OrbitControls
        rotateSpeed={rotateSpeed}
        enableZoom={enableZoom}
        minPolarAngle={minPolarAngle}
      />
    </>
  )
}

const Hotspot = ({ sceneId, idx, navigate }) => {
  const hotspot = useSelector(state => state.tour.scenes[sceneId].hotspots[idx])
  const visited = useSelector(state => state.viewer.sceneHistory.includes(state.tour.scenes[sceneId].hotspots[idx].to))

  // Show labels for the first `scenesToShowLabels` scenes
  // On the last scene, fade out after `fadeOutDelay` ms
  // Don't fade out the label if a hover is in progress (It'll fade out when the hover ends)
  // const scenesToShowLabels = 3 // commenting out because linter says it is never used
  const fadeOutDelay = 3000
  //const labelsAlwaysVisible = useSelector(state => state.viewer.sceneHistory.length <= scenesToShowLabels)
  const labelsAlwaysVisible = true
  //const fadeOutLabels = useSelector(state => state.viewer.sceneHistory.length === scenesToShowLabels)
  const fadeOutLabels = false

  const [labelToggleActive, setLabelToggleActive] = useState(!labelsAlwaysVisible)
  const [labelIsVisible, setLabelIsVisible] = useState(labelsAlwaysVisible)

  const disableLabels = () => {
    setLabelIsVisible(false)
    setLabelToggleActive(true)
  }

  useEffect(() => {
    if (fadeOutLabels) {
      setTimeout(disableLabels, fadeOutDelay)
    }
  }, [fadeOutLabels, fadeOutDelay])

  const [navigateOnTouchEnd, setNavigateOnTouchEnd] = useState(false)

  const handleTouchStart = (e) => {
    setLabelIsVisible(true)
    setNavigateOnTouchEnd(true)
    e.preventDefault()
    e.stopPropagation()
  }

  const handleTouchMove = (e) => {
    if (navigateOnTouchEnd) {
      setNavigateOnTouchEnd(false)
    }
    if (labelIsVisible && labelToggleActive) {
      setLabelIsVisible(false)
    }
    e.preventDefault()
    e.stopPropagation()
  }

  const handleTouchEnd = (e) => {
    if (navigateOnTouchEnd) {
      navigate(`/${hotspot.to}`)
    } else {
      if (labelToggleActive) {
        setLabelIsVisible(false)
      }
    }
    e.preventDefault()
    e.stopPropagation()
  }

  const handleMouseOver = () => {
    if (labelToggleActive) {
      setLabelIsVisible(true)
    }
  }
  const handleMouseOut = () => {
    if (labelToggleActive) {
      setLabelIsVisible(false)
    }
  }
  const handleClick = () => {
    navigate(`/${hotspot.to}`)
  }

  const { pitch, yaw } = hotspot.angle
  const theta = degToRad(pitch)
  const phi = degToRad(yaw)
  const r = 500
  const z = r * Math.sin(phi) * Math.cos(theta)
  const x = r * Math.cos(phi) * Math.cos(theta)
  const y = r * Math.sin(theta)

  return (
    <mesh key={`hotspot-${hotspot.to}-${pitch}-${yaw}`} position={[x, y, z]} >
      <Html center>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "auto", textAlign: "center", transform: "translateY(-25%)" }}>
          <div style={{ transition: "opacity 0.25s", opacity: labelIsVisible ? 1 : 0 }}>
            <Button size="small" className="locationLabel" onClick={() => navigate(`/${hotspot.to}`)}>
              <span className="button-text ui-text">
                <span className="verb meta">{visited ? "Back" : "Go"} To</span>
                <span className="location primary">{format(tour.scenes[hotspot.to].description)}</span>
              </span>
            </Button>
            {/*
            <div className="locationLabel ui-text">
              <span className="verb meta">Go To</span>
              <span className="location primary">{format(tour.scenes[hotspot.to].description)}</span>
            </div>
            */}
          </div>
          <div
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onClick={handleClick}
            className={`hotspot ui${visited && " visited"}`}
          />
        </div>
      </Html>
    </mesh >
  )
}

const Sphere = ({ videoRef, navigate }) => {
  const sceneId = useSelector((state) => state.viewer.sceneHistory[0])
  const northAngle = useSelector((state) => state.tour.scenes[state.viewer.sceneHistory[0]].northAngle)
  const hotspotIndexes = useSelector((state) => state.tour.scenes[state.viewer.sceneHistory[0]].hotspots.map((el, i) => i))

  const defaultRotation = Math.PI * 0.62 // This seems to be a default of the Orbit controls?
  const texture = new VideoTexture(videoRef.current)

  return (
    <group
      rotation={[0, degToRad(northAngle) - defaultRotation, 0]}
    >
      <mesh scale={[-1, 1, 1]}>
        <sphereGeometry attach="geometry" args={[500, 60, 40]} />
        <meshBasicMaterial attach="material" map={texture} side={BackSide} />
      </mesh>
      {hotspotIndexes.map((idx) => <Hotspot key={`${sceneId}-hotspot-${idx}`} sceneId={sceneId} idx={idx} navigate={navigate} />)}
    </group>
  )
}

const PhotoGallery = () => {
  const sceneId = useSelector((state) => state.viewer.sceneHistory[0])

  const memoryIdxs = useSelector((state) => {
    return (state.tour.scenes[state.viewer.sceneHistory[0]].memories.map((el, i) => i))
  })

  // Doing these with state, rather than redux, because changing the context passed through to r3f triggers a rerender even if nothing relevant has changed
  const [shareButtonIsVisible, setShareButtonIsVisible] = useState(false)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    setShareButtonIsVisible(false)
  }, [sceneId])

  const handleScrollDown = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  const handleLastItemVisible = (inView, elementHeight) => {
    if (inView) {
      setShareButtonIsVisible(true)
    } else {
      setShareButtonIsVisible(false)
    }
  }

  return (<div>
    {
      memoryIdxs.length > 0 &&
      <AnimatePresence exitBeforeEnter={true} initial={true}>
        <motion.div
          id="photoGallery"
          initial={{ y: "10vh" }}
          animate={{ y: "-12vh" }}
          exit={{ y: "10vh" }}
          transition={{ delay: 0, duration: 0.75 }}
          key={`${sceneId}`}
        >
          <div className="gallery-spacer-top" />
          <div className="gallery-images" style={{ position: "relative" }}>
            <div className="gallery-images-background" style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0 }} onClick={handleScrollDown} />
            {memoryIdxs.map((idx) =>
              <Memory
                sceneId={sceneId}
                idx={idx}
                key={`${sceneId}-memory-${idx}`}
                onLastItemVisible={handleLastItemVisible}
              />
            )}
          </div>
        </motion.div>
      </AnimatePresence >
    }
    <AnimatePresence AnimatePresence exitBeforeEnter={true} initial={true}>
      {shareButtonIsVisible &&
        <motion.div
          className="gallery-buttons"
          initial={{ y: "10vh" }}
          animate={{ y: "0" }}
          exit={{ y: "10vh" }}
          transition={{ delay: 0, duration: 0.75 }}
        >
          <GalleryButton />
          <ContributeButton />
        </motion.div>}
    </AnimatePresence>
  </div>
  )
}

const Memory = ({ sceneId, idx, onLastItemVisible }) => {
  const memoryCount = useSelector(state => state.tour.scenes[sceneId].memories.length)
  const memory = useSelector(state => state.tour.scenes[sceneId].memories[idx])
  const isLastItem = useSelector(state => idx === state.tour.scenes[sceneId].memories.length - 1)

  // Doing this with state rather than redux because changing context passed through to r3f triggers a redraw even when nothing relevant changes
  const [captionIsVisible, setCaptionIsVisible] = useState(false)

  const rng = seed(memory.media)

  const handleClick = (e) => {
    const bbox = e.target.getBoundingClientRect()
    const activeCenter = window.pageYOffset + bbox.top + vh(5 + idx) - ((vh(100) - bbox.height) / 2)
    const currentScrollState = window.pageYOffset
    const threshold = vh(12.5)
    if (Math.abs(currentScrollState - activeCenter) < threshold) {
      setCaptionIsVisible(!captionIsVisible)
    } else {
      window.scrollTo({
        left: 0,
        top: activeCenter,
        behavior: "smooth",
      })
    }
    e.preventDefault()
    e.stopPropagation()
  }

  const [bbox, setBBox] = useState({ top: 0, left: 0, x: 0, y: 0, width: 0, height: 0 })

  const handleInView = (inView) => {
    if (isLastItem) {
      onLastItemVisible(inView)
    }
  }

  const maxStackHeight = 40
  const itemOffset = maxStackHeight / memoryCount
  const itemRotation = rng() * 4 - 2
  const style = {
    zIndex: memoryCount - idx,
    transform: `rotate(${itemRotation}deg) translate3D(0, ${idx * - itemOffset}px, 0)`,
    position: "sticky",
    width: `${bbox.width}px`,
    height: `${bbox.height}px`,
    bottom: `${-bbox.height}px`,
    marginBottom: isLastItem ? Math.max(vh(5), ((vh(100) - (bbox.height)) / 2)) : null
  }

  const mediaStyle = {
    width: `${bbox.width}px`,
    height: `${bbox.height}px`,
    zIndex: memoryCount - idx,
    cursor: "pointer"
  }


  const panoStyle = {
    zIndex: memoryCount - idx,
    transform: `translate3D(0, ${idx * - itemOffset}px, 0)`,
    position: "sticky",
    width: `${bbox.width}px`,
    height: `${bbox.height}px`,
    bottom: `${-bbox.height}px`,
    marginBottom: isLastItem ? ((vh(100) - (bbox.height)) / 2) : null
  }

  const mediaRef = useRef()

  const playVideo = () => {
    const isPlaying = !!(mediaRef.current.currentTime > 0 && !mediaRef.current.paused && !mediaRef.current.ended && mediaRef.current.readyState > 2)
    if (!isPlaying) {
      mediaRef.current.play()
    }
  }


  useEffect(() => {
    const resizeMedia = () => {
      const maxWidth = vw(100) < 768 ? vw(100) - rem(2) : vw(70)
      const maxHeight = vh(80)
      setPanoramaTargetResolution(resolutions.find(res => res > maxWidth * 2.5) || resolutions[resolutions.length - 1])

      let s
      switch (memory.type) {
        case memoryTypes.PANORAMA:
          const w = 6000
          const h = 4000
          s = Math.min(maxWidth / w, maxHeight / h)
          setBBox({ width: w * s, height: h * s })
          break
        case memoryTypes.IMAGESCROLL:
          const scrollMaxWidth = vw(40)
          s = scrollMaxWidth / memory.width
          setBBox({ width: memory.width * s, height: memory.height * s })
          break
        case memoryTypes.IMAGE:
        case memoryTypes.VIDEO:
        default:
          s = Math.min(maxWidth / memory.width, maxHeight / memory.height)
          setBBox({ width: memory.width * s, height: memory.height * s })
          break
      }
    }
    resizeMedia()
    window.addEventListener("resize", debounce(resizeMedia, 300))
    switch (memory.type) {
      case memoryTypes.VIDEO:
        mediaRef.current.addEventListener("canplay", playVideo)
        mediaRef.current.addEventListener("loadedmetadata", resizeMedia)
        break;
      case memoryTypes.PANORAMA:
        break;
      case memoryTypes.IMAGE:
      default:
        mediaRef.current.addEventListener("load", resizeMedia)
        break;
    }
  }, [memory.media, memory.type, memory.width, memory.height])

  const [panoramaTargetResolution, setPanoramaTargetResolution] = useState(resolutions.find(res => res > vw(85) * 3))
  const defaultResolution = 1678

  switch (memory.type) {
    case memoryTypes.VIDEO:
      return (
        <InView as="figure" key={memory.media} className="memoryWrapper video" style={style} threshold={0.75} onChange={handleInView}>
          <Suspense fallback={<div className="loader" />}>
            <video
              loop
              muted
              playsInline
              autoPlay
              ref={mediaRef}
              crossOrigin="anonymous"
              onClick={handleClick}
              style={mediaStyle}
            >
              <source src={`${process.env.PUBLIC_URL}/assets/media/original/${memory.media}`} />
            </video>
            <figcaption className={`caption${captionIsVisible ? " visible" : " hidden"}`}>
              <span className="caption-text">{format(memory.text)}</span>
            </figcaption>
          </Suspense>
        </InView>
      )
    case memoryTypes.PANORAMA:
      return (
        <InView as="figure" key={memory.media} ref={mediaRef} className="memoryWrapper panorama" style={panoStyle} threshold={0.75} onChange={handleInView}>
          <Suspense fallback={<div className="loader" />}>
            <PanoViewer src={`${process.env.PUBLIC_URL}/assets/media/${panoramaTargetResolution}/${memory.media}`} onClick={handleClick} />
            <figcaption className={`caption${captionIsVisible ? " visible" : " hidden"}`}>
              <span className="caption-text">{format(memory.text)}</span>
            </figcaption>
          </Suspense >
        </InView >
      )
    case memoryTypes.IMAGESCROLL:
      return (
        <InView as="figure" key={memory.media} className="memoryWrapper image" style={{ ...style, transform: `translate3D(0, ${idx * - itemOffset}px, 0)` }} threshold={0.05} onChange={handleInView}>
          <Suspense fallback={<div className="loader" />}>
            <img
              width={memory.width}
              height={memory.height}
              alt={memory.text}
              style={mediaStyle}
              src={`${process.env.PUBLIC_URL}/assets/media/${defaultResolution}/${memory.media}`}
              srcSet={resolutions.map(resolution => `${process.env.PUBLIC_URL}/assets/media/${resolution}/${memory.media} ${resolution}w`).join(", ")}
              onClick={handleClick}
              ref={mediaRef}
            />
            <figcaption className={`caption${captionIsVisible ? " visible" : " hidden"}`} style={{ position: "sticky", marginLeft: "6px", marginBottom: "6px", marginTop: "6px", bottom: "-5vh" }}>
              <span className="caption-text">{format(memory.text)}</span>
            </figcaption>
          </Suspense>
        </InView >
      )
    case memoryTypes.IMAGE:
    default:
      return (
        <InView as="figure" key={memory.media} className="memoryWrapper image" style={style} threshold={0.75} onChange={handleInView}>
          <img
            width={memory.width}
            height={memory.height}
            alt={memory.text}
            style={mediaStyle}
            src={`${process.env.PUBLIC_URL}/assets/media/original/${memory.media}`}
            srcSet={resolutions.filter(width => width <= memory.width).map(width => `${process.env.PUBLIC_URL}/assets/media/${width}/${memory.media} ${width}w`).join(", ")}
            onClick={handleClick}
            ref={mediaRef}
          />
          <figcaption className={`caption${captionIsVisible ? " visible" : " hidden"}`}>
            <span className="caption-text">{format(memory.text)}</span>
          </figcaption>
        </InView>
      )
  }
}

const PanoViewer = ({ src, onClick }) => {
  const rotateSpeed = window.devicePixelRatio > 1 ? -0.5 : -0.9 // slower rotation on high DPI screens
  const enableZoom = false

  const [pointerIsActive, setPointerIsActive] = useState(false)
  const [pointerDidMove, setPointerDidMove] = useState(false)

  const handlePointerStart = (e) => {
    setPointerIsActive(true)
    setPointerDidMove(false)
    e.preventDefault()
    e.stopPropagation()
  }
  const handlePointerMove = (e) => {
    if (pointerIsActive) {
      setPointerDidMove(true)
    }
  }
  const handlePointerEnd = (e) => {
    if (pointerIsActive && !pointerDidMove) {
      setPointerIsActive(false)
      setPointerDidMove(false)
      onClick(e)
    } else {
      setPointerIsActive(false)
      setPointerDidMove(false)
    }
    e.preventDefault()
    e.stopPropagation()
  }

  const texture = useLoader(TextureLoader, src)

  return (
    <>
      <Canvas className="panoViewer"
        onMouseDown={handlePointerStart}
        onMouseMove={handlePointerMove}
        onMouseUp={handlePointerEnd}
        onTouchStart={handlePointerStart}
        onTouchMove={handlePointerMove}
        onTouchEnd={handlePointerEnd}
        style={{ borderRadius: "4px", pointerEvents: "all" }}
      >
        <mesh scale={[-1, 1, 1]}>
          <sphereGeometry attach="geometry" args={[500, 60, 40]} />
          <meshBasicMaterial attach="material" map={texture} side={BackSide} />
        </mesh>
        <OrbitControls
          autoRotate={true}
          autoRotateSpeed={0.5}
          rotateSpeed={rotateSpeed}
          enableZoom={enableZoom}
        />
      </Canvas>
      <div className="panoramaIcon">
        <PanoramaIcon config={{ size: "3em", color: "rgba(255, 255, 255, 1)" }} />
      </div>
    </>
  )
}


const GalleryButton = () => {

  const navigate = useNavigate()
  const handleClick = (e) => { navigate(`/gallery`) }

  return (
    <Button size={"large"} style={{ pointerEvents: "all" }} onClick={handleClick}>
      <span className="button-text ui-text"><span className="primary">Browse all Photos</span></span>
      <span className="button-icon"><GalleryIcon /></span>
    </Button>
  )
}

export default Viewer
